// extracted by mini-css-extract-plugin
export var advantagesContainer = "Advantages-module--advantages-container--7XaGO";
export var cardInfos = "Advantages-module--card-infos--hCzRq";
export var cardList = "Advantages-module--card-list--loHE4";
export var gatsbyFocusWrapper = "Advantages-module--gatsby-focus-wrapper--csfb6";
export var hideOnDesktop = "Advantages-module--hide-on-desktop--YQtqb";
export var hideOnMobile = "Advantages-module--hide-on-mobile--72+qq";
export var iconCar = "Advantages-module--icon-car--YINJd";
export var iconCars = "Advantages-module--icon-cars--qolqJ";
export var iconClock = "Advantages-module--icon-clock--6qvD-";
export var iconEletricCar = "Advantages-module--icon-eletric-car--BP358";
export var iconFacebook = "Advantages-module--icon-facebook--WhMPv";
export var iconGear = "Advantages-module--icon-gear--b5iuj";
export var iconInstagram = "Advantages-module--icon-instagram--jFNAh";
export var iconKm = "Advantages-module--icon-km--Y1ed1";
export var iconPhone = "Advantages-module--icon-phone--4ywJK";
export var iconRight = "Advantages-module--icon-right--+MLUP";
export var iconWhatsapp = "Advantages-module--icon-whatsapp--3yGw6";
export var imageWrapper = "Advantages-module--image-wrapper--2W-Mv";
export var pageWidth = "Advantages-module--page-width--Cn9Ha";
export var path1 = "Advantages-module--path1--eEZoP";
export var path2 = "Advantages-module--path2--weLQc";
export var path3 = "Advantages-module--path3--40ItJ";
export var sub = "Advantages-module--sub--dmmjy";
export var view = "Advantages-module--view--dhlcX";
export var views = "Advantages-module--views--i8bQD";