import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useMediaQuery } from 'react-responsive'

import * as st from '~/assets/styl/WhyDimas.module.styl'
import porqueDimas from '~/assets/img/porque-dimas.jpg'

export default function WhyDimas() {
  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })

  return (
    <div
      id="porque-contratar"
      className={st.whyContainer}
      style={{ backgroundImage: isDesktop ? `url(${porqueDimas})` : '' }}
    >
      <section>
        <h2>Por que contratar a frota Dimas Sempre?</h2>
        <p>
          Temos uma equipe especializada em frotas corporativas, oferecendo
          soluções completas para{' '}
          <b>
            otimizar a mobilidade do seu negócio e diminuir seus custos de
            operação.
          </b>
        </p>
        <AnchorLink to="/terceirizacao-de-frota/#contrate">
          Contrate agora
        </AnchorLink>
      </section>
    </div>
  )
}
