import React from 'react'
import { Head } from '~/components'
import Menu from '~/components/Menu'
import Banner from '~/components/Banner'

import bannerTerceirizacao from '~/assets/img/banner-terceirizacao.jpg'
import WhyDimas from '~/components/WhyDimas'
import Advantages from '~/components/Advantages'
import OutsourcingContactForm from '~/components/OutsourcingContactForm'
import Footer from '~/components/Footer'
import useScroll from '~/components/useScroll'

const TerceirizacaoDeFrotas = () => {
  useScroll()
  return (
    <>
      <Head />
      <Banner
        image={bannerTerceirizacao}
        title="Procurando veículos para a sua empresa?"
        buttonText="Conheça as vantagens"
        anchor="/terceirizacao-de-frota/#vantagens"
        maxWidth={780}
      />
      <WhyDimas />
      <Advantages />
      <OutsourcingContactForm />
      <Footer />
    </>
  )
}

export default TerceirizacaoDeFrotas
