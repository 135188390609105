import React from 'react'
import cn from 'classnames'
import { LazyImage } from 'react-lazy-images'
import { advantagesCards } from '~/data'

import * as st from '~/assets/styl/Advantages.module.styl'

export default function Advantages() {
  return (
    <section
      id="vantagens"
      className={cn(st.advantagesContainer, 'page-width')}
    >
      <h2>Conheça as vantagens</h2>
      <p className={st.sub}>
        Muito além de confiança, produtividade e redução de custos
      </p>
      <ul className={st.cardList}>
        {advantagesCards.map((card, index) => (
          <li key={index}>
            <div>
              <div className={st.imageWrapper}>
                {card.icon.map((icon, id) => (
                  <LazyImage
                    src={icon}
                    key={id}
                    placeholder={({ ref }) => (
                      <img ref={ref} key={id} src={icon} />
                    )}
                    actual={({ imageProps }) => <img {...imageProps} />}
                  />
                ))}
              </div>
              <section className={st.cardInfos}>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </section>
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}
