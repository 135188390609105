// extracted by mini-css-extract-plugin
export var contactContainer = "OutsourcingContactForm-module--contact-container--UMHCK";
export var contactWrapper = "OutsourcingContactForm-module--contact-wrapper--8ubtQ";
export var formWrapper = "OutsourcingContactForm-module--form-wrapper--KM4Ai";
export var gatsbyFocusWrapper = "OutsourcingContactForm-module--gatsby-focus-wrapper--2MFYw";
export var hideOnDesktop = "OutsourcingContactForm-module--hide-on-desktop--ntVIw";
export var hideOnMobile = "OutsourcingContactForm-module--hide-on-mobile--ZzsXe";
export var iconCar = "OutsourcingContactForm-module--icon-car--9G5lj";
export var iconCars = "OutsourcingContactForm-module--icon-cars--rQHG2";
export var iconClock = "OutsourcingContactForm-module--icon-clock--htvdF";
export var iconEletricCar = "OutsourcingContactForm-module--icon-eletric-car--E59fD";
export var iconFacebook = "OutsourcingContactForm-module--icon-facebook--hkcZn";
export var iconGear = "OutsourcingContactForm-module--icon-gear--m-wZT";
export var iconInstagram = "OutsourcingContactForm-module--icon-instagram--Ba-Yg";
export var iconKm = "OutsourcingContactForm-module--icon-km--Qopy4";
export var iconPhone = "OutsourcingContactForm-module--icon-phone--z0NUS";
export var iconRight = "OutsourcingContactForm-module--icon-right--HfKAb";
export var iconWhatsapp = "OutsourcingContactForm-module--icon-whatsapp--SeXpw";
export var pageWidth = "OutsourcingContactForm-module--page-width--Fmq3U";
export var path1 = "OutsourcingContactForm-module--path1--NS032";
export var path2 = "OutsourcingContactForm-module--path2--Ktuf0";
export var path3 = "OutsourcingContactForm-module--path3---QiDI";
export var privacy = "OutsourcingContactForm-module--privacy--rQt38";
export var view = "OutsourcingContactForm-module--view--Bkfxw";
export var views = "OutsourcingContactForm-module--views--tZ8Qt";