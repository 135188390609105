// extracted by mini-css-extract-plugin
export var gatsbyFocusWrapper = "WhyDimas-module--gatsby-focus-wrapper--7Ypuf";
export var hideOnDesktop = "WhyDimas-module--hide-on-desktop--4NIn8";
export var hideOnMobile = "WhyDimas-module--hide-on-mobile--Hxa-2";
export var iconCar = "WhyDimas-module--icon-car--k+z6e";
export var iconCars = "WhyDimas-module--icon-cars--r4Fpq";
export var iconClock = "WhyDimas-module--icon-clock--55GHf";
export var iconEletricCar = "WhyDimas-module--icon-eletric-car--m1Lis";
export var iconFacebook = "WhyDimas-module--icon-facebook--PgnqQ";
export var iconGear = "WhyDimas-module--icon-gear--qJw7f";
export var iconInstagram = "WhyDimas-module--icon-instagram--rizPp";
export var iconKm = "WhyDimas-module--icon-km--d+xAc";
export var iconPhone = "WhyDimas-module--icon-phone--A3ZQ-";
export var iconRight = "WhyDimas-module--icon-right--IZ0pR";
export var iconWhatsapp = "WhyDimas-module--icon-whatsapp--klRif";
export var pageWidth = "WhyDimas-module--page-width--Jqktj";
export var path1 = "WhyDimas-module--path1--qn0l1";
export var path2 = "WhyDimas-module--path2--WrpRK";
export var path3 = "WhyDimas-module--path3--WuPPF";
export var view = "WhyDimas-module--view--jULv8";
export var views = "WhyDimas-module--views--XKuFl";
export var whyContainer = "WhyDimas-module--why-container--ChyIA";