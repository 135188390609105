import React, { useState } from 'react'

import * as st from '~/assets/styl/OutsourcingContactForm.module.styl'

import bgTerceirizacao from '~/assets/img/form-terceirizacao-background.jpg'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import requestData from '~/siteApi/form.js'

import formatPhone from '~/utils/formatPhone'
import { useCookies } from 'react-cookie'
import { navigate } from 'gatsby'

export default function OutsourcingContactForm() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [termo, setTermo] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [communications, setCommunications] = useState(false)
  const [cookies] = useCookies()
  const terms_of_use = termo
  const privacy_policy = privacy
  const Celular = phone

  function handleName(e) {
    setName(e.target.value)
  }

  function handleEmail(e) {
    setEmail(e.target.value)
  }

  function handlePhone(e) {
    setPhone(formatPhone(e.target.value))
  }

  function handleTermo(e) {
    setTermo(e.target.checked)
    setPrivacy(e.target.checked)
  }

  function handleComunicacao(e) {
    setCommunications(e.target.checked)
  }

  function handleSubmit(e) {
    e.preventDefault()
    requestData('post', 'conversions', {
      name,
      email,
      Celular,
      legal_bases: [
        {
          category: 'communications',
          type: 'consent',
          status: 'granted',
        },
        {
          category: 'data_processing',
          type: 'consent',
          status: 'granted',
        },
      ],
      token_rdstation: '36ef8b1445846a96de8abe06eaf566be',
      identificador: 'Site - Terceirização de frota',
      traffic_source: cookies?.['__trf.src'] || cookies?.__utmzz,
      __utmz: cookies?.['__trf.src'] || cookies?.__utmzz,
    }).then((data) => {
      if (data.result === 'success') navigate('/obrigado')
      else alert(data.msg)
    })
  }

  return (
    <div
      id="contrate"
      className={st.contactContainer}
      style={{ backgroundImage: `url(${bgTerceirizacao})` }}
    >
      <section className={st.contactWrapper}>
        <h2>Ficou interessado?</h2>
        <p>
          Deixe seus dados, nossa equipe de consultores entrará em contato com
          você.
        </p>
        <form onSubmit={handleSubmit}>
          <div className={st.formWrapper}>
            <label>
              <input
                type="text"
                placeholder="Nome"
                value={name}
                onChange={handleName}
                required
              />
            </label>
            <label>
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={handleEmail}
                required
              />
            </label>
            <label>
              <input
                type="tel"
                placeholder="Whatsapp"
                value={phone}
                onChange={handlePhone}
                maxLength={15}
                required
              />
            </label>
          </div>
          <div className={st.privacy}>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                name="termo"
                onChange={handleTermo}
                required
              />
              <small>
                Eu concordo em receber comunicações. Ao informar meus dados, eu
                concordo com a
                <AnchorLink to="/politica-de-privacidade/">
                  Política de privacidade
                </AnchorLink>
                e<AnchorLink to="/termos-de-uso/">Termos de uso</AnchorLink>
              </small>
            </label>
            <label>
              <input
                id="use_terms"
                type="checkbox"
                name="comunicacao"
                onChange={handleComunicacao}
                required
              />
              <small>
                Autorizo o Grupo Dimas a armazenar meus dados pessoais para
                enviar campanhas de marketing e informações sobre a empresa nos
                canais: Telefone, Email e SMS.
              </small>
            </label>
          </div>
          <button type="submit">Solicitar Contato</button>
        </form>
      </section>
    </div>
  )
}
